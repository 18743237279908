import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonAppBar from './AppBar';
import Footer from './Footer';
import ControlledCarousel from './CarouselImage';
import LandingPageCard from './LandingPageCard';

export default function HomePage() {
  return (
    <div className="App">
      <header>
      <ButtonAppBar /> 
      </header>
      <main>
        <ControlledCarousel />   
        <LandingPageCard />
      </main>
      <Footer />
    </div>
  );
}
