import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonAppBar from '../components/AppBar';
import Footer from '../components/Footer';
import Overview from '../components/about/Overview';
import Vission from '../components/about/Vission';

export default function HomePage() {
  return (
    <div className="App">
      <header>
      <ButtonAppBar /> 
      </header>
      <main>
      <Overview /> 
      <Vission />
      </main>
      <Footer />
    </div>
  );
}
