import React from 'react';
import { Container, Row , Col} from 'react-bootstrap';
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaRegIdCard } from "react-icons/fa6";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from 'react-router-dom';
import "../index.css"


export default function Footer() {
  return (
    <footer style={{ background: '#2E3B55', color: 'white', textAlign: 'center', padding: '10px' }}>
        <Container>
        <Row style={{marginTop:"10px" }} className="footerCol justify-content-center">
        <Col xs={12} md={1}  ></Col>
        <Col xs={12} md={4}  style={{marginBottom:"10px"}}  >
        <b>Perusahaan TTHI</b>
        <br />
        <p>Kampung Titi Haji Idris<br/>
        06400 Langgar, Kedah, Malaysia
       </p></Col>
        <Col xs={12} md={4}  style={{marginBottom:"10px"}}  ><b>Contact Us</b>
        <br/>
        <FaRegIdCard />  <span style={{paddingLeft:"1vh"}}>Hasbul Qawim Hasan</span> <br/>
        <BsFillTelephoneFill /> <span style={{paddingLeft:"1vh"}}>+60195577453 </span> <br />
        <HiOutlineMail /> <span style={{paddingLeft:"1vh"}}>perusahaantthi@gmail.com</span>
        </Col>
        <Col xs={12} md={2} style={{marginBottom:"10px"}} ><b>SiteMap</b><br/>
        <Link to="/about" style={{ textDecoration: 'none', color:"white" }}>About Us</Link><br/>
        
        <Link to="/gallery" style={{ textDecoration: 'none', color:"white" }}>Gallery</Link>
        </Col>
        <Col xs={12} md={1}></Col>
      </Row>
   

              
        
         </Container>
        <p>Copyright ©2024 Perusahaan TTHI. All Rights Reserved.</p>
        </footer>
  );
}
