import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Row, Col } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import CornAbout from "../../assets/corn_about.jpg";
export default function Vission() {

    const isMatch = useMediaQuery('(max-width:1170px)');
    const config = isMatch ? {display:"block"}: { backgroundImage: "url(/corn_about.jpg)", backgroundSize: "90vh 100vh", backgroundRepeat:"no-repeat",backgroundPosition: "left", display: "flex", }
    const config2 = isMatch ? {display:"block",width:"100%"} : {display:"none"}

  return (
    
    <Container sx={{ flexGrow: 1 }} style={{paddingBottom:"10vh"}} >
          
       <Row>
        <Col xs={12} md={12}>
            <img src={CornAbout} alt="vission" style={config2}/>
        </Col>

       </Row>
<Row style={config}>
    <Col xs={12} md={5}></Col>
    <Col xs={12} md={7}  style={{paddingTop:"40px",paddingBottom:"40px"}}>
    <Paper elevation={3} square={false}>
    <Card variant="outlined" style={{backgroundColor:"#f1e5d9"}}>
      <CardContent>
        <Typography gutterBottom variant="h3" component="div">
          <strong>Our Vision </strong>
        </Typography>
        <Typography variant="body" color="text.secondary">
        To be a pioneer in the agricultural sector, recognized for our dedication to quality, innovation, and sustainability. We strive to enhance the productivity and profitability of our partners through our expertise and commitment to excellence.
        </Typography>
        <br/><br/>
        <Typography gutterBottom variant="h3" component="div">
          <strong>Our Mission </strong>
        </Typography>
       
      <Typography variant="body" color="text.secondary">
        To make an impact in the agricultural sector by providing high-quality products and services that meet the needs of our customers by focussing 
        on the food needs of the Malaysia in term of quality and value of the market.
        
        </Typography>  
      </CardContent>
      </Card>
      </Paper>
    </Col>
    </Row>

            
        
    </Container>


    );
}
