import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Row, Col } from 'react-bootstrap';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function Overview() {

    const isMatch = useMediaQuery('(max-width:1170px)');
    const config = isMatch ? {textAlign:'center'} : {textAlign:'left'}
    return (
        <Container style={{padding:'100px 10px 10px 10px'}} >
            <Typography variant="h2" gutterBottom >
            <span style={{color:"#2E3B55",fontWeight:"bold",textDecorationLine:"underline",textDecorationColor:"orange"}}>About </span> Us
      </Typography>

      <Paper elevation={0} >
    <Row className="justify-content-xs-center">
        <Col xs={12} md={10} style={config} >
        <Typography variant="h5" gutterBottom style={{padding:'10px',color:"#808080",fontFamily:"poppins,Sans-Serif", fontWeight:"500"}}>
            <span >At Perusahaan TTHI we are comitted to provide high-quality agricultural products to our customers. We are one of the few 
            <strong style={{color:"orange"}}> agriculture company</strong> that are focuses on sustainaibility farming practices and innovative agricultural techniques.</span>
        </Typography>

        </Col>
        
    </Row>
        
      </Paper>

        </Container>
    );
}