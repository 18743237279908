import Carousel from 'react-bootstrap/Carousel';
import CornFieldImage from '../assets/corn_field.jpg';
import CornPaddyImage from '../assets/corn_paddy.jpg';
import CornSunsetImage from '../assets/corn_sunset.jpg';
import "../index.css"

function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item>
        <div className='.imgBox'>
        <img src={CornFieldImage} style={{height:500, width:'100%'}}/>
        </div>
     
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={CornPaddyImage} style={{height:500, width:'100%'}}/>
        <Carousel.Caption>
    
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={CornSunsetImage}style={{height:500, width:'100%'}} />
        <Carousel.Caption>
        
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;