import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { Row, Col } from 'react-bootstrap';
import GrainCornImage from '../assets/grain_corn.jpg';
import SilageCornImage from '../assets/silage_corn.jpg';
import CornSeedImage from '../assets/seed.jpg';




export default function ActionAreaCard() {
  return (
    <Row style={{display:'flex', justifyContent: 'space-between',marginBottom:'20px',marginLeft:'40px'}}>
        <Col xs={12} md={4}  style={{marginBottom:'10px'}}>
        <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={GrainCornImage}
          
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Grain Corn
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          We specialize in the cultivation and production of premium corn silage, providing a reliable source of high-quality feed for livestock.            </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Col>
        <Col xs={12} md={4} style={{marginBottom:'10px'}} >
        <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={SilageCornImage}
       
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Silage Corn
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            We produce high-quality silage corn, ensuring a consistent and reliable supply for a high-quality feed for livestock.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Col>

        <Col xs={12} md={4} style={{marginBottom:'10px'}}>
        <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={CornSeedImage}
          
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Seed Supply & Consultancy
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Helping farmers to achieve the best possible yield by providing high-quality seeds and expert consultancy services.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Col>
    
    </Row>
  );
}