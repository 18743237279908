import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';


export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}  style={{ background: '#2E3B55', height:'100%' }}>
      <List>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <ListItem  disablePadding>
            <ListItemButton>
              
              <ListItemText primary={"Home"}  style={{ color: 'white' }} / >
            </ListItemButton>
          </ListItem>
          </Link>
          
          <Link to="/about" style={{ textDecoration: 'none' }}>
          <ListItem  disablePadding>
            <ListItemButton>
              
              <ListItemText primary={"About Us"}  style={{ color: 'white' }} / >
            </ListItemButton>
          </ListItem>
          </Link>

          <Link to="/gallery" style={{ textDecoration: 'none' }}>
          <ListItem  disablePadding>
            <ListItemButton>
              
              <ListItemText primary={"Gallery"}  style={{ color: 'white' }} / >
            </ListItemButton>
          </ListItem>
          </Link>
      </List>

    </Box>

  
  
  );

  return (
    <div  style={{ background: '#2E3B55' }}>
        <IconButton
            onClick={toggleDrawer(true)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            
            <MenuIcon />
            </IconButton>
        <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}

    
      </Drawer>
    </div>
  );
}