import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Image1 from '../../assets/gallery/image_1.jpg';
import Image2 from '../../assets/gallery/image_2.jpg';
import Image3 from '../../assets/gallery/image_3.jpg';
import Image4 from '../../assets/gallery/image_4.jpg';
import Image5 from '../../assets/gallery/image_5.jpg';
import Image6 from '../../assets/gallery/image_6.jpg';
import Image7 from '../../assets/gallery/image_7.jpg';
import Image8 from '../../assets/gallery/image_8.jpg';
import Image9 from '../../assets/gallery/image_9.jpg';
import Image10 from '../../assets/gallery/image_10.jpg';



export default function QuiltedImageList() {
  return (
    <Container style={{padding:'100px 10px 10px 10px'}}>
         <Typography variant="h2" gutterBottom >
         <span style={{color:"#2E3B55",fontWeight:"bold",textDecorationLine:"underline",textDecorationColor:"orange"}}>Gallery </span> 
         </Typography>
         <ImageList sx={{ width: "100%", height: "100%" }} cols={3} rowHeight={164}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
          style={{width:"100%",height:"100%"}}
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
    
    </Container>
  );
}

const itemData = [
  {
    img: Image1,
    title: 'Breakfast',
    
  },
  {
    img: Image2,
    title: 'Burger',
  },
  {
    img: Image3,
    title: 'Camera',
  },
  {
    img: Image4,
    title: 'Coffee',
    
  },
  {
    img: Image5,
    title: 'Hats',
    
  },
  {
    img: Image7,
    title: 'Basketball',
  },
  {
    img: Image8,
    title: 'Fern',
  },
  {
    img: Image9,
    title: 'Mushrooms',
   
  },
  {
    img:Image10,
    title: 'Tomato basil',
  },
 

];