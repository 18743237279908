import { Container, Row, Col , Card} from 'react-bootstrap';
import { BsFillHandThumbsUpFill,BsRecycle ,BsFillPeopleFill} from "react-icons/bs";
import ProductCard from './ProductCard';




export default function PageCard() {

  return (
    <Container  style={{padding:'20px 10px 10px 10px'}} >
    <Row style={{marginBottom:'20px'}}><h1><u >Why Choose Us</u></h1></Row>
    <Row style={{display:'flex', justifyContent: 'space-between',marginBottom:'20px'}}>
        <Col xs={12} md={4} >
        <Card style={{ width: '100%', height:'20rem', marginRight:'10px', marginBottom:'10px'  }}>
            <Card.Body>
                <Card.Title style={{textAlign:"center"}}>
                    <p><BsFillHandThumbsUpFill style={{width:'100%', height:'3rem'}} /></p>
                    <h2>Quality</h2>
                </Card.Title>
                <Card.Text style={{paddingTop:'20px',textAlign:'center'}}>
                Our products are made from the best quality resources. We ensure that our products are free from any harmful chemicals and we produce Grade A quality products.
                </Card.Text>
        
            </Card.Body>
            </Card></Col>
        <Col xs={12} md={4}>
        <Card style={{ width: '100%',height:'20rem',marginRight:'10px', marginBottom:'10px' }}>
  <Card.Body>
  <Card.Title style={{textAlign:"center"}}>
                    <p><BsRecycle style={{width:'100%', height:'3rem'}} /></p>
                    <h2>Sustainability</h2>
                </Card.Title>
    <Card.Text style={{paddingTop:'20px',textAlign:'center'}}>
      We aim to deliver the food needs of the world without compromising the ability of future generations to meet their own needs.
        We will provide end to end resources from the farm to the table.
    </Card.Text>
   
  </Card.Body>
    </Card></Col>
        <Col xs={12} md={4}>
        <Card style={{ width: '100%',height:'20rem',marginRight:'10px', marginBottom:'10px'  }}>
  <Card.Body>
  <Card.Title style={{textAlign:"center"}}>
                    <p><BsFillPeopleFill style={{width:'100%', height:'3rem'}} /></p>
                    <h2>Integrity</h2>
                </Card.Title>
        <Card.Text style={{paddingTop:'20px',textAlign:'center'}}>
        We are committed to providing the best quality products to our customers. We are transparent in our dealings and trustworthy in our partnership practices.
    </Card.Text>
    
  </Card.Body>
</Card></Col>

</Row>
<Row style={{marginBottom:'20px'}}><h1><u >What We Do</u></h1></Row>
<ProductCard />

</Container>
  );
}