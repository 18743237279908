import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonAppBar from '../components/AppBar';
import Footer from '../components/Footer';
import ImageTable from '../components/gallery/ImageTable';

export default function HomePage() {
  return (
    <div className="App">
      <header>
      <ButtonAppBar /> 
      </header>
      <main>
      <ImageTable/>
      </main>
      <Footer />
    </div>
  );
}
